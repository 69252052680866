<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :class="cssClass" :ripple="false">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "TemenoButton",
  props: ["action"],
  computed: {
    cssClass() {
      return (this.action === "" || Boolean(this.action))
        ? "action"
        : "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.theme--dark {
  &.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: $color-background;
    border: 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    box-shadow: inset 3px 4px 4px rgb(30, 150, 225, 0.2), inset -5px -5px 7px rgb(0, 25, 30, 1);
    color: #fff;
    font-weight: bold;

    &.action {
      background: $color-background-lighten-1;
      box-shadow: inset 3px 4px 4px rgb(30, 150, 225, 0.3), inset -5px -5px 7px rgb(0, 25, 30, 0.7);

      &:hover {
        background: $color-background-lighten-1;
        box-shadow: inset 6px 6px 5px rgb(0, 25, 30, 0.7), inset -3px -4px 5px rgb(30, 150, 225, 0.3);
      }
    }

    &::before {
      content: none;
    }

    &:hover {
      background: $color-background;
      box-shadow: inset 6px 6px 5px rgb(0, 25, 30, 1), inset -3px -4px 5px rgb(30, 150, 225, 0.3);
    }

    &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $color-background !important;
      color: $text-color-disabled !important;

      &.action {
        background-color: $color-background-lighten-1 !important;
      }
    }
  }
}
</style>
